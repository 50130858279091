import { H as K, L as D } from "./LayoutGrid-C1ObcOdJ.mjs";
import { A as $t, B as Ot, C as Vt, a as qt, J as Gt, b as Wt, c as Kt } from "./LayoutGrid-C1ObcOdJ.mjs";
import { P as z, a as J, b as U } from "./VirtualScroll-1wNcHAF3.mjs";
import { A as Jt, C as Ut, c as Qt, d as Xt, D as Yt, i as Zt, e as St, f as te, g as ee, h as ne, s as se, u as oe, y as ae, B as re, j as ie, T as le, E as ce, F as de, V as ue, v as pe, n as me, o as fe, m as ge, r as be, p as _e, q as ve, k as he, l as Te, x as ye, w as xe, t as je, G as Le, z as Ce } from "./VirtualScroll-1wNcHAF3.mjs";
import { j as o, c as f, L as H, I as Q, C as X, T as Y, a as Z } from "./ToggleSwitch-DvVsR0h0.mjs";
import { d as Ne, R as ke, b as Ae, e as we, f as Ee } from "./ToggleSwitch-DvVsR0h0.mjs";
import { useRef as I, useState as $, useEffect as R, useCallback as b, useMemo as O, useId as V } from "react";
import "react-dom";
async function S(t) {
  if (!t)
    return !1;
  await navigator.clipboard.writeText(t);
}
const wt = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function tt(t, e, r) {
  const a = I(null), i = I(null), [s, d] = $(null);
  return R(() => {
    i.current = e ?? null;
  }, [e]), R(() => (a.current = new MutationObserver((l) => {
    for (const u of l)
      (typeof i.current != "function" || i.current(u)) && d(u);
  }), a.current.observe(t.current, r), () => {
    var l;
    (l = a.current) == null || l.disconnect();
  }), []), s;
}
function Et(t, e) {
  const r = b(
    (s) => s.type === "attributes" && s.attributeName === e,
    [e]
  ), a = tt(t, r, { attributes: !0 });
  return O(
    () => {
      var s;
      return { oldValue: (a == null ? void 0 : a.oldValue) ?? null, newValue: (s = t.current) == null ? void 0 : s.getAttribute(e) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [a, e]
  );
}
const et = "_copyTextContainer_190y6_2", nt = "_copyTextInput_190y6_7", M = {
  copyTextContainer: et,
  copyTextInput: nt
}, st = "_modal_vtej9_1", ot = "_modalInner_vtej9_50", at = "_modalHeader_vtej9_54", rt = "_closeButton_vtej9_69", it = "_modalContent_vtej9_75", lt = "_modalActions_vtej9_79", v = {
  modal: st,
  modalInner: ot,
  modalHeader: at,
  closeButton: rt,
  modalContent: it,
  modalActions: lt
};
function ct(t) {
  t && (!("close" in t) || typeof t.close != "function" || t.close());
}
function Pt({
  id: t,
  isOpen: e,
  onClose: r,
  onOpen: a,
  onCloseComplete: i,
  onOpenComplete: s,
  title: d,
  customActions: l,
  children: u,
  manualFocusRef: g,
  closeText: T,
  closeOnClickOutside: N = !0,
  hideHeader: k = !1,
  hideActions: y = !1,
  classNames: p = {}
}) {
  const _ = I(null), A = I(null), [q, w] = $(!0), B = b(() => g || (A.current ? A : _), [g]), G = V(), E = t || G, x = b((n) => {
    var F;
    if (n.key !== "Tab") return;
    const c = (F = _.current) == null ? void 0 : F.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ), m = c == null ? void 0 : c[0], C = c == null ? void 0 : c[c.length - 1];
    if (n.shiftKey && document.activeElement === m) {
      n.preventDefault(), C == null || C.focus();
      return;
    }
    !n.shiftKey && document.activeElement === C && (n.preventDefault(), m == null || m.focus());
  }, []), j = b(() => {
    i == null || i(), w(!0);
  }, [i]), L = b(() => {
    var n;
    s == null || s(), w(!0), (n = _.current) == null || n.removeAttribute("inert");
  }, [s]);
  R(() => {
    var c;
    w(!1);
    const n = _.current;
    if (n) {
      if (e) {
        n.showModal();
        const m = B();
        (c = m == null ? void 0 : m.current) == null || c.focus(), n.removeEventListener("transitionend", j), n.addEventListener("keydown", x), n.addEventListener("transitionend", L), a == null || a();
      } else
        n.removeEventListener("keydown", x), n.removeEventListener("transitionend", L), n.addEventListener("transitionend", j), ct(n), n.setAttribute("inert", "");
      return () => {
        n == null || n.removeEventListener("keydown", x), n.removeEventListener("transitionend", j), n.removeEventListener("transitionend", L);
      };
    }
  }, [B, x, e, j, a, L]);
  const W = b(
    (n) => {
      const { current: c } = _;
      n.target === c && r();
    },
    [r]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ o.jsxs(
      "dialog",
      {
        id: E,
        ref: _,
        className: f(v.modal, p.modal),
        onCancel: r,
        onClick: N ? W : void 0,
        "aria-labelledby": `modal-title-${E}`,
        "aria-modal": "true",
        title: d,
        tabIndex: -1,
        children: [
          /* @__PURE__ */ o.jsxs(H, { space: "400", className: f(v.modalInner, p.modalInner), children: [
            !k && /* @__PURE__ */ o.jsxs("div", { className: f(v.modalHeader, p.titleContainer), children: [
              /* @__PURE__ */ o.jsx(K, { level: "h200", as: "h2", id: `modal-title-${E}`, children: d }),
              /* @__PURE__ */ o.jsx(
                Q,
                {
                  kind: "plain",
                  className: v.closeButton,
                  onClick: r,
                  ref: A,
                  type: "button",
                  iconName: "x",
                  iconProps: { title: T, color: "fg-content-primary" }
                }
              )
            ] }),
            /* @__PURE__ */ o.jsx("div", { className: f(v.modalContent, p.contentContainer), children: (e || !q) && u }),
            !y && /* @__PURE__ */ o.jsx("div", { className: f(v.modalActions, p.actionsContainer), children: l || /* @__PURE__ */ o.jsx(X, { onClick: r, type: "button", children: T }) })
          ] }),
          e && /* @__PURE__ */ o.jsx("div", { id: "bp-portal-target", style: { position: "absolute", top: 0, left: 0 }, role: "alert" })
        ]
      }
    )
  );
}
const Rt = ({
  textToCopy: t,
  showPreview: e = !1,
  previewLabel: r,
  children: a,
  onTextCopied: i,
  onError: s
}) => {
  const d = b(() => {
    t && S(t).then(() => i == null ? void 0 : i(t)).catch((u) => s == null ? void 0 : s(u));
  }, [t, i, s]), l = O(() => a(d), [a, d]);
  return e ? /* @__PURE__ */ o.jsxs(D, { className: M.copyTextContainer, children: [
    /* @__PURE__ */ o.jsx(
      Y,
      {
        className: M.copyTextInput,
        readOnly: !0,
        label: r,
        value: t
      }
    ),
    l
  ] }) : l;
}, dt = "_fieldset_hid4k_1", ut = "_fieldContainer_hid4k_30", pt = "_groupLabel_hid4k_34", mt = "_labelLarge_hid4k_39", ft = "_groupHint_hid4k_43", gt = "_groupError_hid4k_48", bt = "_errorMessage_hid4k_57", _t = "_disabledLabels_hid4k_64", h = {
  fieldset: dt,
  fieldContainer: ut,
  groupLabel: pt,
  labelLarge: mt,
  groupHint: ft,
  groupError: gt,
  errorMessage: bt,
  disabledLabels: _t
};
function vt({ className: t, hint: e, required: r, children: a }) {
  return /* @__PURE__ */ o.jsxs("legend", { className: t, children: [
    /* @__PURE__ */ o.jsxs("div", { className: h.groupLabel, children: [
      a,
      r && /* @__PURE__ */ o.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    e ? /* @__PURE__ */ o.jsx("div", { className: h.groupHint, children: e }) : null
  ] });
}
function Bt({
  children: t,
  classNames: e,
  legend: r,
  hint: a,
  required: i,
  error: s,
  layout: d = "horizontal",
  layoutProps: l = {
    space: "none"
  },
  errorDisplay: u = "all",
  ...g
}) {
  const T = f(h.fieldContainer, l == null ? void 0 : l.className, {
    [h.groupError]: !!s && u !== "text"
  }), N = d === "vertical" ? H : D, k = V(), y = g.id || k, p = s ? `${y}-error` : void 0;
  return /* @__PURE__ */ o.jsxs(
    "fieldset",
    {
      id: y,
      className: f(h.fieldset, e == null ? void 0 : e.fieldset),
      "aria-describedby": p,
      ...g,
      children: [
        r || a ? /* @__PURE__ */ o.jsx(vt, { required: i, hint: a, className: e == null ? void 0 : e.legend, children: r }) : null,
        /* @__PURE__ */ o.jsx(N, { className: T, ...l, children: t }),
        s && u !== "border" ? /* @__PURE__ */ o.jsx("p", { id: p, className: h.errorMessage, "aria-live": "polite", role: "alert", children: s }) : null
      ]
    }
  );
}
const ht = "_tooltipContent_upgbl_1", Tt = "_spyElement_upgbl_12", yt = "_opacityTransition_upgbl_18", xt = "_opacityTransitionOpen_upgbl_23", P = {
  tooltipContent: ht,
  spyElement: Tt,
  opacityTransition: yt,
  opacityTransitionOpen: xt
};
function Ft(t) {
  return /* @__PURE__ */ o.jsx(
    z,
    {
      ...t,
      Anchor: J,
      triggerEvents: ["hover", "focus"],
      contentClassName: P.tooltipContent,
      contentWrapperClassName: P.opacityTransition,
      popupOpenWrapperClassName: P.opacityTransitionOpen,
      preferredPopupPosition: U.TOP
    }
  );
}
const jt = "_tooltipTrigger_36bik_1", Lt = {
  tooltipTrigger: jt
}, Mt = ({ type: t = "question", title: e = "More information", className: r }) => /* @__PURE__ */ o.jsx("button", { className: f(Lt.tooltipTrigger, r), type: "button", children: /* @__PURE__ */ o.jsx(Z, { name: t, title: e }) });
export {
  $t as Alert,
  Jt as Alignment,
  wt as AllowedInputTypes,
  Ut as AmountField,
  Ot as Badge,
  X as Button,
  Vt as Callout,
  qt as Card,
  Qt as Checkbox,
  Xt as ColorInput,
  Rt as CopyText,
  Yt as Dropdown,
  Zt as DropdownLabel,
  St as DropdownMenu,
  te as DropdownMenuItem,
  ee as DropdownMenuSeparator,
  ne as DropdownTrigger,
  Bt as Fieldset,
  K as Heading,
  Z as Icon,
  Q as IconButton,
  Gt as JSONPrettyPrint,
  Wt as LayoutCenter,
  D as LayoutCluster,
  Kt as LayoutGrid,
  Pt as LayoutModal,
  H as LayoutStack,
  Ne as LoadingSkeleton,
  se as POPUP_CONTAINER_ATTR,
  oe as POPUP_TRIGGER_ATTR,
  z as Popup,
  ae as PopupContext,
  U as Position,
  re as RadioButton,
  ke as RangeInput,
  ie as Select,
  Y as TextInput,
  Ae as TextInputWrapper,
  we as TextLink,
  le as Textarea,
  Ee as Tile,
  ce as ToggleButton,
  de as ToggleSwitch,
  Ft as Tooltip,
  Mt as TooltipTrigger,
  ue as VirtualScroll,
  pe as findElementPopupParent,
  me as findFirstFocusableChild,
  fe as findFirstFocusableChildOrRoot,
  ge as findFocusableChildren,
  be as findNextFocusableElementExcludingChildren,
  _e as findNextFocusableSibling,
  ve as findPreviousFocusableSibling,
  he as getAriaDescribedBy,
  Te as getAriaRole,
  ye as getScrollParent,
  xe as isElementTriggerWithinTarget,
  je as tick,
  Et as useAttributeObserver,
  Le as useForwardedRef,
  tt as useMutationObserver,
  Ce as usePopupContext
};

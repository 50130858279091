'use client'
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unused-modules */
function absTopOffset(el: HTMLElement): number {
  let input: HTMLElement | null = el
  let offset = 0
  do {
    offset += input.offsetTop
    input = input.offsetParent as HTMLElement | null
  } while (input)
  return offset
}

export const scrollToTop = () => window.scroll(0, 0)

export function scrollToRef(ref: React.MutableRefObject<HTMLElement | null>) {
  if (!ref.current) return
  scrollToNode(ref.current)
}

export function scrollToNode(node: HTMLElement) {
  window.requestAnimationFrame(() => {
    const offsetTop = absTopOffset(node)
    window.scroll(0, offsetTop)
  })
}

export function postMessageScrollTop() {
  if (window.parent) window.parent.postMessage('bpScrollTop', '*')
}
